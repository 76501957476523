import React from "react";
import Adam from './img/Plain/Adam.png';
import Eve from './img/Plain/Eve.png';
import './Intro.css'

export default function Title() {
    return(
        <div id='intro-title'>
            <div id='intro-title-front'>Bear </div>
            <img id="adam-title" src={Adam} alt='Adam'></img>
            <img id="eve-title" src={Eve} alt='Eve'></img>
            <div id='intro-title-back'> Fruits</div>
        </div>
    )
}

