import React from "react";
import Cycle from "./img/Cycle.png";
import "./Cycle.css";

export default function HomeCycle() {
  return (
    <>
      <div id="cycle-title">Bear Fruits Cycle</div>
      <div id="cycle-container">
        <div className="cycle-image-container">
          <img id="cycle" src={Cycle} alt="cycle" />
        </div>
        <h2>It's a simple spell but quite unbreakable. --Dr.Strange</h2>
      </div>
    </>
  );
};
