import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Apple from './img/Plain/Apple.png';
import Banana from './img/Plain/Banana.png';
import Carob from './img/Plain/Carob.png';
import Citron from './img/Plain/Citron.png';
import Fig from './img/Plain/Fig.png';
import Grape from './img/Plain/Grape.png';
import Mushroom from './img/Plain/Mushroom.png';
import Pomegranate from './img/Plain/Pomegranate.png';
import Quince from './img/Plain/Quince.png';

import './HomeTitle.css'

const fruits = [Apple, Banana, Carob, Citron, Fig, Grape, Mushroom, Pomegranate, Quince];

const HomeTitle = () => {
  const [randomFruit] = useState(fruits[Math.floor(Math.random() * fruits.length)]);
  const navigate = useNavigate();

  const handleMintClick = () => {
    navigate('/mint');
  };

  // const handleClaimClick = () => {
  //   navigate('/claim');
  // };

  const handleDocsClick = () => {
    window.location.href = 'https://docs.bearfruits.art';
  };

  const handleTwitterClick = () => {
    window.open('https://twitter.com/BearFruitsNFT', '_blank');
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/WutBzpX6xM', '_blank');
  };

  const handleOpenseaClick = () => {
    window.open('https://opensea.io/collection/bear-fruits', '_blank');
  };

  const handleEtherscanClick = () => {
    window.open('https://etherscan.io/address/0xec66b54722ab45ad874597a48915fca41b00b8aa#code', '_blank');
  };

  return (
    <div id='title'>
        <div id="home-title">
            <img id="fruit-title" src={randomFruit} alt='forbidden'></img>
            <div id='title-front'>Bear Fruits</div>
        </div>
        <button className="title-button" onClick={handleDocsClick}>Docs</button>
        <button className="title-button" onClick={handleTwitterClick}>Twitter</button>
        <button className="title-button" onClick={handleDiscordClick}>Discord</button>
        <button className="title-button" onClick={handleEtherscanClick}>Etherscan</button>
        <button className="title-button" onClick={handleOpenseaClick}>Opensea</button>
        <button id="mint-button" className="title-button" onClick={handleMintClick}>
          Mint
        </button>
        {/* <button id="claim-button" className="title-button" onClick={handleClaimClick}>
          Claim Royalty
        </button> */}
    </div>
  );
};

export default HomeTitle;