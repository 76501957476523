import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Title from './Title';
import './Intro.css';

export default function Intro() {
    return(
        <div>
            <Title />
            <Story />
        </div>
    )
}

function Story() {
    const [lineOneOpacity, setLineOneOpacity] = useState(0);
    const [lineTwoOpacity, setLineTwoOpacity] = useState(0);
    const [lineThreeOpacity, setLineThreeOpacity] = useState(0);
    const [lineFourOpacity, setLineFourOpacity] = useState(0);
    const [enterButtonOpacity, setEnterButtonOpacity] = useState(0);

    const handleEnterButtonClick = () => {
      localStorage.setItem('introShown', true);
  }

  let navigate = useNavigate();
  const routeChange = () =>{
    let path = `/`;
    navigate(path);
  }

    useEffect(() => {
      setLineOneOpacity(1);
    }, []);

    useEffect(() => {
      if (lineOneOpacity === 1) {
        setTimeout(() => {
          setLineTwoOpacity(1);
        }, 1000);
      }
    }, [lineOneOpacity]);

    useEffect(() => {
      if (lineTwoOpacity === 1) {
        setTimeout(() => {
          setLineThreeOpacity(1);
        }, 1000);
      }
    }, [lineTwoOpacity]);

    useEffect(() => {
      if (lineThreeOpacity === 1) {
        setTimeout(() => {
          setLineFourOpacity(1);
        }, 1000);
      }
    }, [lineThreeOpacity]);

    useEffect(() => {
      if (lineFourOpacity === 1) {
        setTimeout(() => {
          setEnterButtonOpacity(1);
        }, 1000);
      }
    }, [lineFourOpacity]);

    return (
      <div id="story">
        <p id="story-line-one" style={{ opacity: lineOneOpacity }} className={lineOneOpacity === 1 ? 'fade-in' : ''}>
        Long, long ago, Adam and Eve indulged in the Forbidden Fruit and were cast out from the Garden of Eden.
        </p>

        <p style={{ opacity: lineTwoOpacity }} className={lineTwoOpacity === 1 ? 'fade-in' : ''}>
        But before their exile, they took with them other Forbidden Fruits...
        </p>

        <p style={{ opacity: lineThreeOpacity }} className={lineThreeOpacity === 1 ? 'fade-in' : ''}>
        As the seasons shifted, each fruit bloomed continuously and bore fruit that replenished its keepers.
        </p>

        <p style={{ opacity: lineFourOpacity }} className={lineFourOpacity === 1 ? 'fade-in' : ''}>
        At the end of their journey, they realized they had returned to the very Garden of Eden they were once cast from.
        </p>

        <div id='enter-button-container'>
            <button id='enter-button' style={{ opacity: enterButtonOpacity }} className={enterButtonOpacity === 1 ? 'fade-in' : ''} onClick={ () => {handleEnterButtonClick(); routeChange();}}>Enter</button>
        </div>
      </div>
    );
  }