import React, { useEffect, useState, useCallback } from "react";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from "web3";
import "./MintPage.css";
import A from "./A.json";
import L from './L.json';

const contractAddress = "0xEc66B54722Ab45AD874597A48915fCa41B00b8AA";
const injectedConnector = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 11155111],
});
const INFURA_PROJECT_ID = "0ba25ea2378f45e7821245971a10c7ad"; // Replace with your Infura project ID

const infuraProvider = new Web3.providers.HttpProvider(`https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`);

const currChainID = 1;

const ConnectWalletButton = ({ setAccount, setChainId }) => {
  const { activate, active, account: web3Account, chainId: web3ChainId } = useWeb3React();
  const [networkError, setNetworkError] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      await activate(injectedConnector);
    } catch (err) {
      console.error("Failed to connect to wallet:", err);
    }
  }, [activate]);

  useEffect(() => {
    handleClick();
  }, [handleClick]); // Add handleClick to the dependency array

  useEffect(() => {
    if (web3ChainId && web3ChainId !== currChainID) {
      setNetworkError(true);
    } else {
      setNetworkError(false);
    }
  }, [web3ChainId]);

  useEffect(() => {
    setAccount(web3Account);
    setChainId(web3ChainId);
  }, [web3Account, web3ChainId, setAccount, setChainId]);

  const displayWalletAddress = () => {
    return web3Account.slice(0, 6) + '...' + web3Account.slice(-4);
  };

  return (
    <>
      <button className="connect-wallet-button" onClick={handleClick}>
        {active ? displayWalletAddress() : 'Connect Wallet'}
      </button>
      {networkError && (
        <div className="network-error">
          Please switch to the Ethereum mainnet.
        </div>
      )}
    </>
  );
};

const MintPage = () => {
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [library, setLibrary] = useState(null);
  const [contractInstance, setContractInstance] = useState(null);
  const [forbiddenFruitSupplyLeft, setForbiddenFruitSupplyLeft] = useState(0);
  const [goldenAppleSupplyLeft, setGoldenAppleSupplyLeft] = useState(0);

  useEffect(() => {
    if (library && account) {
      const contract = new library.eth.Contract(A, contractAddress);
      setContractInstance(contract);
    }
  }, [library, account]);

  const fetchSupplyCounts = async () => {
    try {
      const web3 = new Web3(infuraProvider);
      const contract = new web3.eth.Contract(A, contractAddress);
      const forbiddenFruitCount = await contract.methods.forbiddenFruitCounter().call();
      const goldenAppleCount = await contract.methods.goldenAppleCounter().call();
      setForbiddenFruitSupplyLeft(5600 - forbiddenFruitCount + 1);
      setGoldenAppleSupplyLeft(500 - goldenAppleCount + 5601);
    } catch (error) {
      console.error("Error fetching supply counts:", error);
    }
  };

  useEffect(() => {
    fetchSupplyCounts();
  }, []);


  const getMintIsActive = async () => {
    if (!contractInstance) return false;
    try {
      const isActive = await contractInstance.methods.mintIsActive().call();
      return isActive;
    } catch (error) {
      console.error("Error fetching mintIsActive:", error);
      return false;
    }
  };

  const mintForbiddenFruit = async () => {
    if (!account) {
      alert("Please connect your wallet.");
      return;
    }

    if (chainId !== currChainID) {
      alert("Please switch to the Ethereum mainnet.");
      return;
    }

    const isActive = await getMintIsActive();
    if (!isActive) {
      alert("Mint is not active yet.");
      return;
    }

    const publicMint = await contractInstance.methods.allowlistMintActive().call();

    if (publicMint) {
      if (L.includes(account)) {
        const c = 916 + 1225;
        try {
          await contractInstance.methods
            .mintForbiddenFruit(c)
            .send({ from: account });
        } catch (error) {
          console.error("Error minting Forbidden Fruit:", error);
        }
      } else {
        alert("Not on allowlist");
      }
    } else {
      try {
        await contractInstance.methods
          .mintForbiddenFruit(0)
          .send({ from: account });
      } catch (error) {
        console.error("Error minting Forbidden Fruit:", error);
      }
    }
  };

  const mintGoldenApple = async () => {
    if (!account) {
      alert("Please connect your wallet.");
      return;
    }

    if (chainId !== currChainID) {
      alert("Please switch to the Ethereum mainnet.");
      return;
    }

    const isActive = await getMintIsActive();
    if (!isActive) {
      alert("Mint is not active yet.");
      return;
    }

    const forbiddenFruitCount = await contractInstance.methods.forbiddenFruitCounter().call();
    const hasMintedForFree = await contractInstance.methods.hasMintedForFree(account).call();
    const paymentAmount = (forbiddenFruitCount <= 5600 || hasMintedForFree) ? '0.2' : '0';

    try {
      await contractInstance.methods.mintGoldenApple().send({ from: account, value: Web3.utils.toWei(paymentAmount, 'ether') });
    } catch (error) {
      console.error("Error minting Golden Apple:", error);
    }
  };

  return (
    <Web3ReactProvider getLibrary={(provider) => {
        const web3 = new Web3(provider);
        setLibrary(web3);
        return web3;
      }}>
      <div className="mint-page">
        <ConnectWalletButton setAccount={setAccount} setChainId={setChainId} />
        <div className="mint-options">
          <div className="mint-option">
            <button className="mint-button" onClick={mintForbiddenFruit}>
              Mint Forbidden Fruit
            </button>
            <p>Cost: 0 ETH</p>
            <p>Supply Left : {forbiddenFruitSupplyLeft} / 5600</p>
          </div>
          <div className="mint-option">
            <button className="mint-button golden-button" onClick={mintGoldenApple}>
              Mint Golden Apple
            </button>
            <p>Cost: 0.2 ETH</p>
            <p>Supply Left : {goldenAppleSupplyLeft} / 500</p>
            <p>*Free mint if all Forbidden Fruits are minted</p>
          </div>
        </div>
        <p className="mint-note">
          Each wallet may mint one Bear Fruits NFT for free.
        </p>
      </div>
    </Web3ReactProvider>
  );
};

export default MintPage;