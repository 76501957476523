import React, { useEffect } from "react";
import "./GridImg.css";
import Banana from "./img/Plain/Banana.png";
import Carob from "./img/Plain/Carob.png";
import Citron from "./img/Plain/Citron.png";
import Fig from "./img/Plain/Fig.png";
import Mushroom from "./img/Plain/Mushroom.png";
import Pomegranate from "./img/Plain/Pomegranate.png";
import Quince from "./img/Plain/Quince.png";

import Apple from "./img/Plain/Apple.png";
import Grape from "./img/Plain/Grape.png";

const GridImg = () => {
  useEffect(() => {
    const items = document.querySelectorAll(".grid-item");
    const itemCount = items.length;
    const circleRadius = 300;
    const angleStep = (2 * Math.PI) / itemCount;

    const updatePosition = () => {
      items.forEach((item, index) => {
        const angle = (index * angleStep) + (Date.now() / 2000 % (2 * Math.PI));
        const x = circleRadius * Math.cos(angle);
        const y = circleRadius * Math.sin(angle);

        item.style.transform = `translate(${x}px, ${y}px)`;
      });

      requestAnimationFrame(updatePosition);
    };

    updatePosition();
  }, []);

  return (
    <div id="grid-img-container">
      <FruitGrid />
    </div>
  );
};

const FruitGrid = () => {
  const items = [
    { src: Banana, name: "Banana" },
    { src: Carob, name: "Carob" },
    { src: Citron, name: "Citron" },
    { src: Fig, name: "Fig" },
    { src: Mushroom, name: "Mushroom" },
    { src: Pomegranate, name: "Pomegranate" },
    { src: Quince, name: "Quince" },
    { src: Grape, name: "Grape" },
    { src: Apple, name: "Apple" },
  ];

  return (
    <div className="grid-container">
      {items.map((item) => (
        <div key={item.name} className="grid-item">
          <img src={item.src} alt={item.name} />
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default GridImg;
