import React from 'react';
import './HomeText.css';

export default function HomeText() {
    return (
        <div id='home-text-container'>
            <div id='home-text'>
                <p>
                Bear Fruits is an experimental NFT collection that distributes dividends on a reucrring basis to its holders.
                </p>

                <p>
                The dividends come from the royalties generated from secondary sales.
                </p>

                <p>
                100% royalties are all distributed to Bear Fruits holders.
                </p>

                <p>
                Mint is live.
                </p>
            </div>
        </div>
    )
}
