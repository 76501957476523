import React from 'react';
import HomeTitle from './HomeTitle';
import HomeText from './HomeText';
import GridImg from './GridImg';
import HomeCycle from './Cycle';
// import AsSeenOn from './AsSeenOn';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {

    const navigate = useNavigate();

    const introCheck = () => {
        const introShown = localStorage.getItem('introShown');
        if (!introShown) {
            navigate("/intro");
        }
        // If uncomment below, intro will show everytime home page is loaded
        // else {
        //     console.log('test');
        //     navigate("/intro");
        // }
    }

    useEffect(() => {
        introCheck();
    });

    return(
        <div id='home'>
            <HomeTitle />
            <HomeText />
            <HomeCycle />
            <GridImg />
            {/* <AsSeenOn /> */}
        </div>
    )
}