import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Web3 from 'web3';
import Intro from './Intro';
import Home from './Home';
import MintPage from './MintPage';
// import ClaimRoyaltyPage from './ClaimRoyaltyPage';

const getLibrary = (provider) => {
  return new Web3(provider);
};

const Router = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Routes>
          <Route path="/intro" element={<Intro />} />
          <Route path="/" element={<Home />} />
          <Route path="/mint" element={<MintPage />} />
          {/* <Route path="/claim" element={<ClaimRoyaltyPage />} /> */}
        </Routes>
      </BrowserRouter>
    </Web3ReactProvider>
  );
};

export default Router;

